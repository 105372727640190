import React from "react";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from "@mui/material";
import MemoryIcon from "@mui/icons-material/Memory";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BatteryIcon from "./batteryIcon";
import UpdateIcon from "@mui/icons-material/Update";

const NodStatus = (props) => {
  const status = props.status;
  const updateRequested = props.updateRequested;
  const updateTime = props.updateTime;
  const demoMode = props.demoMode;

  return (
    <Box>
      <List sx={{ maxWidth: 500 }}>
        {status && (
          <>
            {demoMode && (
              <Alert severity="info" sx={{ marginBottom: 1 }}>
                <AlertTitle>Demo mode</AlertTitle>
                Data displayed is fictional and may only be used for demo
                purposes.
                <br />
                <strong>
                  Exit the demo mode and connect to a NOD device to get real
                  data
                </strong>
              </Alert>
            )}
            <ListItem>
              <ListItemAvatar>
                <Avatar variant="rounded">
                  <MemoryIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Firmware"
                secondary={status.softwareVersion}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar variant="rounded">
                  <BatteryIcon level={status.batteryVoltage / 3.0} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Backup battery"
                secondary={
                  Math.min(
                    Math.round((status.batteryVoltage / 3.0) * 100.0),
                    100.0
                  ) + "%"
                }
              />
            </ListItem>
            <ListItem
              secondaryAction={
                <Tooltip title="Update NOD device time to match your local computer's set time">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<UpdateIcon />}
                    onClick={updateTime}
                  >
                    Sync time
                  </Button>
                </Tooltip>
              }
            >
              <ListItemAvatar>
                <Avatar variant="rounded">
                  <AccessTimeIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Device time"
                secondary={status.rtcDateTime}
              />
            </ListItem>
          </>
        )}
        {!status && (
          <ListItem>
            <ListItemText primary="No device info available. Please update device info." />
          </ListItem>
        )}
      </List>
      <Button
        variant="contained"
        onClick={updateRequested}
        sx={{ marginTop: 2 }}
      >
        Update device info
      </Button>
    </Box>
  );
};

export default NodStatus;
