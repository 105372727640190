import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

const ConnectButton = (props) => {
  const [error, setError] = React.useState(undefined);

  const handleClose = () => {
    setError(undefined);
  };

  const connect = async () => {
    try {
      const port = await navigator.serial.requestPort({
        filters: [{ usbVendorId: 1027 }],
      });
      if (port) {
        try {
          await port.open({
            baudRate: 38400,
            dataBits: 8,
            stopBits: 1,
            parity: "none",
          });
          props.handleConnect(port);
        } catch (e) {
          setError(e);
        }
      } else {
        setError("Valid NOD port not selected");
      }
    } catch (e) {
      console.error("Error selecting NOD serial port: " + e);
    }
  };
  return (
    <>
      <Button
        variant="contained"
        onClick={connect}
        sx={{ display: "block", marginBottom: 1 }}
      >
        Connect to NOD
      </Button>
      <Dialog
        open={error !== undefined}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Error connecting to NOD"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>{String(error) || JSON.stringify(error)}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConnectButton;
