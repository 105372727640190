import {
  add,
  differenceInCalendarMonths,
  format,
  intervalToDuration,
} from "date-fns";
import { getDaysOfMonth } from "./util";
import { demoData } from "./demoData";

class NodClient {
  constructor(port, dataRecieved, errorRecieved, deviceDisconnected) {
    this.port = port;
    this.encoder = new TextEncoder();
    this.decoder = new TextDecoder();
    this.data = [];
    this.dataRecieved = dataRecieved;
    this.errorRecieved = errorRecieved;
    this.deviceDisconnected = deviceDisconnected;
    this.reader = undefined;
    this.requestQueue = [];
  }

  async getStatus() {
    const command = "a,0\n";
    const writer = this.port.writable.getWriter();
    await writer.write(this.encoder.encode(command));
    writer.releaseLock();
  }

  async requestData(startDate, endDate) {
    this.data = [];
    this.requestQueue = this.generateGetDataCommands(startDate, endDate);
    await this.getData();
  }

  async requestDemoData(startDate) {
    let resultData = [];
    if (demoData) {
      for (let i = 0; i < demoData.data.length; i++) {
        const currentDate = add(startDate, { days: i });
        demoData.data[i].forEach((d) => {
          resultData.push({
            date: `${(currentDate.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${currentDate
              .getDate()
              .toString()
              .padStart(2, "0")}`,
            time: d.time,
            value: d.value ? d.value : "NaN",
          });
        });
      }
      this.data = [{ type: "data", data: resultData }];
      this.dataRecieved(this.data);
    }
  }

  async getData() {
    if (this.requestQueue && this.requestQueue.length > 0) {
      const writer = this.port.writable.getWriter();
      const command = this.requestQueue.splice(0, 1)[0];
      await writer.write(this.encoder.encode(command));
      writer.releaseLock();
    }
  }

  async setTime() {
    const dateTimeString = format(new Date(), "yyyy,MM,dd,HH,mm,ss");
    const command = `d,${dateTimeString}\n`;
    const writer = this.port.writable.getWriter();
    await writer.write(this.encoder.encode(command));
    writer.releaseLock();
  }

  async readData() {
    while (this.port.readable) {
      if (!this.reader) this.reader = this.port.readable.getReader();
      try {
        let message = "";
        while (true) {
          const { value, done } = await this.reader.read();
          if (done) {
            // |reader| has been canceled.
            break;
          }
          const data = this.decoder.decode(value);
          message += data;
          if (data.trim().endsWith("@")) {
            const result = this.parseData(message);
            message = "";
            if (result && result.type === "data") {
              this.data.push(result);
              this.dataRecieved(this.data);
              await new Promise((r) => setTimeout(r, 2000));
              await this.getData(); // Get more data, if needed
            } else {
              if (result) this.dataRecieved(result);
            }
          }
        }
      } catch (error) {
        console.error(error);
        this.errorRecieved(error);
      } finally {
        this.reader.releaseLock();
        this.deviceDisconnected();
      }
    }
  }

  parseData(data) {
    try {
      if (data.startsWith("a,0")) {
        /*
            Medclair program Mar 10 2022
            HW:0x01FFE01F 0xAEAE9C21 0x557B08A1 0xF5002147 
            SW:01.00.00
            RTC 2022-07-07 03:07:30
            Raw x 3979
            fRTCBatVolt 3.21
        */
        const dataRows = data.substring(5).split("\r");
        const programDate = dataRows[0].substring(17).trim();
        const hardwareData = dataRows[1].substring(3).trim().split(" ");
        const softwareVersion = dataRows[2].substring(4).trim();
        const rtcDateTime = dataRows[3].substring(4).trim();
        const batteryVoltage = dataRows[5].substring(12).trim();
        return {
          type: "status",
          data: {
            programDate,
            hardwareData,
            softwareVersion,
            rtcDateTime,
            batteryVoltage,
          },
        };
      }

      if (data.startsWith("p,")) {
        const dataRows = data.split("\r\n");
        return {
          type: "data",
          data: dataRows
            .slice(1, -2)
            .filter((r) => !isNaN(r.charAt(0)))
            .map((r) => {
              const data = r.split(" ");
              const value = Number(data[2]);
              if (value < -100)
                this.errorRecieved(
                  "Please contact Medclair for support, info@medclair.com."
                );
              return {
                date: data[0],
                time: data[1],
                value: !isNaN(value) && value < 0 ? 0 : value,
              };
            }),
        };
      }

      if (data.startsWith("d,")) {
        return {
          type: "timeupdate",
          data: {
            success: true,
          },
        };
      }
    } catch (error) {
      console.error("Error parsing NOD message: " + JSON.stringify(error));
    }

    console.log("Unknown NOD message: " + data);
  }

  generateGetDataCommands(startDate, endDate) {
    let commands = [];
    const duration = intervalToDuration({
      start: startDate,
      end: endDate,
    });
    const months = differenceInCalendarMonths(endDate, startDate);
    console.log("M: " + months);
    for (let i = 0; i <= months; i++) {
      const currentDate = add(startDate, { months: i });
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      let day = "01";
      let numberOfDays = getDaysOfMonth(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1
      );
      if (currentDate.getMonth() === startDate.getMonth() && months > 0) {
        day = startDate.getDate().toString().padStart(2, "0");
        numberOfDays -= startDate.getDate() - 1;
      }
      if (months === 0) {
        day = startDate.getDate().toString().padStart(2, "0");
        numberOfDays = duration.days + 1;
      }
      if (i > 0 && i === months) {
        numberOfDays = endDate.getDate();
      }

      commands.push(`p,${month},${day},${numberOfDays}\n`);
    }
    return commands;
  }
}

export default NodClient;
