import { format } from "date-fns";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export const getCsvData = (data, serial) => {
  return [{ date: getExportHeader(serial) }, ...data];
};

export const exportToExcel = (data, serial, filename) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const workSheet = XLSX.utils.json_to_sheet([
    { date: getExportHeader(serial) },
    ...data,
  ]);
  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  const blobData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blobData, filename + fileExtension);
};

const getExportHeader = (serial) => {
  return `Export date: ${format(
    new Date(),
    "yyyy-MM-dd HH:mm"
  )}, NOD s/n: ${serial}`;
};
