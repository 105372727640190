import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

const NodGraph = (props) => {
  const data = props.data;
  return (
    <LineChart
      width={500}
      height={300}
      data={data}
      margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
    >
      <Line
        type="monotone"
        dataKey="max"
        stroke="#096365"
        name="Daily peak ppm"
      />
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
      <XAxis dataKey="name" />
      <YAxis
        allowDataOverflow={true}
        domain={([dataMin, dataMax]) => [0, Math.max(200, dataMax)]}
      />
      <Tooltip />
    </LineChart>
  );
};

export const transformNodDataToGraphData = (nodData) => {
  const graphData = new Map();
  nodData.forEach((d) => {
    if (!isNaN(d.value)) {
      if (graphData.has(d.date)) {
        const oldValues = graphData.get(d.date);
        if (oldValues.max < d.value)
          graphData.set(d.date, {
            max: Number(d.value),
          });
      } else {
        graphData.set(d.date, { max: Number(d.value) });
      }
    }
  });
  return Array.from(graphData, ([key, value]) => {
    return { name: key, max: value.max };
  });
};

export default NodGraph;
