import React from "react";
import { Button } from "@mui/material";

const DemoButton = (props) => {
  const handleClick = () => {
    props.handleClick();
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClick}
        sx={{ display: "block" }}
      >
        Demo mode
      </Button>
    </>
  );
};

export default DemoButton;
