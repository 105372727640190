import { Container, Typography, Box } from "@mui/material";
import React from "react";

const Footer = (props) => {
  return (
    <Box
      sx={{
        color: "#fff",
        backgroundColor: "#096365",
        marginTop: 5,
        padding: 5,
        position: "absolute",
        bottom: 0,
        width: "100%",
      }}
    >
      <Container>
        <Typography align="center">
          <b>Note:</b> This website does not communicate any data submitted or
          entered to any external party.
          <br />
          All data is stored in your local web browser and will be cleared when
          closing the browser window.
        </Typography>
        <Typography align="center" sx={{ marginTop: 1 }}>
          &copy; Copyright {new Date().getFullYear()}, Medclair AB (556772-8893)
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
