import { Alert, AlertTitle } from "@mui/material";
import React from "react";

const UserMessage = (props) => {
  const type = props.type;
  const message = props.message;
  const hideMessage = props.hideMessage;
  return (
    <Alert severity={type} onClose={hideMessage}>
      <AlertTitle>{getTitle(type)}</AlertTitle>
      {message}
    </Alert>
  );
};

export default UserMessage;

const getTitle = (type) => {
  switch (type.toLowerCase()) {
    case "error":
      return "Error";
    case "info":
      return "Information";
    default:
      return "Message";
  }
};
