import React, { useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Box,
  CircularProgress,
  Container,
  createTheme,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from "@mui/material";
import "./App.css";
import ConnectButton from "./components/connectButton";
import Menu from "./components/menu";
import NodClient from "./lib/nodClient";
import NodStatus from "components/nodStatus";
import { TabContext, TabPanel } from "@mui/lab";
import NodDataTable from "components/nodDataTable";
import { LocalizationProvider } from "@mui/x-date-pickers";
import UserMessage from "components/userMessage";
import deviceList from "./assets/deviceList.png";
import Footer from "components/footer";
import DemoButton from "components/demoButton";

function App() {
  const [nodClient, setNodClient] = React.useState(undefined);
  const [connected, setConnected] = React.useState(false);
  const [demoMode, setDemoMode] = React.useState(false);
  const [value, setValue] = React.useState("0");
  const [statusLoading, setStatusLoading] = React.useState(false);
  const [status, setStatus] = React.useState(undefined);
  const [nodDataLoading, setNodDataLoading] = React.useState(false);
  const [nodData, setNodData] = React.useState(undefined);
  const [message, setMessage] = React.useState(undefined);
  const [messageType, setMessageType] = React.useState("error");

  let port = null;

  const handleConnect = async (connectedPort) => {
    port = connectedPort;
    setNodClient(
      new NodClient(port, dataRecieved, errorRecieved, deviceDisconnected)
    );
    setConnected(true);
    setNodDataLoading(false);
    setNodData(undefined);
    setStatusLoading(false);
    setStatus(undefined);
    setMessage(undefined);
  };

  const handleDemoMode = async () => {
    setNodClient(new NodClient(null, dataRecieved, errorRecieved));
    setConnected(false);
    setDemoMode(true);
    setStatus({
      softwareVersion: "DEMO 1.0",
      batteryVoltage: 3.0,
      rtcDateTime: new Date().toISOString(),
    });
  };

  useEffect(() => {
    if (nodClient && !demoMode) nodClient.readData();
    setTimeout(() => updateStatus(), 2000);
  }, [nodClient, demoMode]);

  const updateTime = async () => {
    if (nodClient) {
      nodClient.setTime();
    }
  };

  const updateStatus = () => {
    if (nodClient && !demoMode) {
      nodClient.getStatus();
      setStatusLoading(true);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleGetNewData = (startDate, endDate) => {
    if (nodClient && connected) {
      setNodDataLoading(true);
      nodClient.requestData(startDate, endDate);
    }
    if (demoMode) {
      setNodDataLoading(true);
      nodClient.requestDemoData(startDate);
    }
  };

  const handleHideMessage = () => {
    setMessage(undefined);
  };

  const dataRecieved = async (data) => {
    if (Array.isArray(data)) {
      setNodData(data.flatMap((d) => d.data));
      setNodDataLoading(false);
      return;
    }
    if (data.type === "status") {
      setStatus(data.data);
      setStatusLoading(false);
      return;
    }
    if (data.type === "timeupdate") {
      return;
    }
    console.log("Unknown datatype");
  };

  const errorRecieved = (error) => {
    setMessageType("error");
    if (error.stack && error.stack === "Error: The device has been lost.") {
      setConnected(false);
      setMessage(
        "Lost connection to the NOD device. Please check your cable and reconnect."
      );
    } else if (error && String(error) && String(error).startsWith("Please")) {
      setMessageType("info");
      setMessage(error);
    } else {
      setMessage(error.stack ? error.stack : error);
    }
  };

  const deviceDisconnected = () => {
    setConnected(false);
    setMessage(
      "Lost connection to the NOD device. Please check your cable and reconnect."
    );
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#096365",
      },
      secondary: {
        main: "#ffd70f",
      },
    },
    typography: {
      fontFamily: ["futura-pt", "Roboto"].join(","),
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <Menu connected={connected} demoMode={demoMode} />
        <Container sx={{ backgroundColor: "#fff", marginBottom: 2 }}>
          {message && (
            <UserMessage
              type={messageType}
              message={message}
              hideMessage={handleHideMessage}
            />
          )}
          {!connected && !demoMode && (
            <Stack direction="row">
              <Box sx={{ marginRight: 2, marginTop: 2 }}>
                <ConnectButton handleConnect={handleConnect} />
                <DemoButton handleClick={handleDemoMode} />
              </Box>
              <Box sx={{ maxWidth: 500 }}>
                <Typography variant="h3">Get started</Typography>
                <p>
                  In order to read data from your NOD device, press the{" "}
                  <i>CONNECT TO NOD</i> button to the left. This will bring up a
                  list of connected devices. Select your NOD device from the
                  list and press <i>Connect</i>.
                </p>
                <img
                  src={deviceList}
                  style={{ width: 450 }}
                  alt="Device list"
                />
              </Box>
            </Stack>
          )}
          {(connected || demoMode) && (
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Data"
                    value="0"
                    icon={
                      nodDataLoading ? <CircularProgress size={20} /> : <></>
                    }
                    iconPosition="end"
                  />
                  <Tab
                    label="Device info"
                    value="1"
                    icon={
                      statusLoading ? <CircularProgress size={20} /> : <></>
                    }
                    iconPosition="end"
                  />
                </Tabs>
              </Box>
              <TabPanel value="0">
                <NodDataTable
                  data={nodData}
                  status={status}
                  loading={nodDataLoading}
                  demoMode={demoMode}
                  dataRequested={handleGetNewData}
                />
              </TabPanel>
              <TabPanel value="1">
                <NodStatus
                  status={status}
                  updateRequested={updateStatus}
                  updateTime={updateTime}
                  demoMode={demoMode}
                />
              </TabPanel>
            </TabContext>
          )}
        </Container>
        <Footer />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
