import React from "react";
import { Box, Chip, Container, Typography } from "@mui/material";
import logo from "../assets/Medclair_logo.png";

const Menu = (props) => {
  return (
    <Box sx={{ backgroundColor: "rgba(0,0,0,0.21)", mb: 2, pt: 1 }}>
      <Container>
        <img
          alt="logo"
          src={logo}
          style={{ width: "30vmin", marginRight: 25 }}
        />
        <Typography color={"#777"} variant="h3" component="span">
          NOD Reader
        </Typography>
        <Typography component="span" sx={{ ml: 3 }}>
          Status:{" "}
        </Typography>
        <Chip
          label={
            props.connected
              ? "CONNECTED"
              : props.demoMode
              ? "DEMO MODE"
              : "NOT CONNECTED"
          }
          color={
            props.connected ? "success" : props.demoMode ? "warning" : "error"
          }
          size="small"
          sx={{ mb: 1 }}
        />
      </Container>
    </Box>
  );
};

export default Menu;
