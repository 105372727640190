import React, { useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SensorsOffIcon from "@mui/icons-material/SensorsOff";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import TableChartIcon from "@mui/icons-material/TableChart";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import NodGraph, { transformNodDataToGraphData } from "./nodGraph";
import { CSVLink } from "react-csv";
import { exportToExcel, getCsvData } from "lib/export";

const NodDataTable = (props) => {
  const data = props.data;
  const status = props.status;
  const loading = props.loading;
  const dataRequested = props.dataRequested;
  const demoMode = props.demoMode;

  const [filterShowAllRows, setFilterShowAllRows] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [endDateInvalid, setEndDateInvalid] = React.useState(false);
  const [valueRows, setValueRows] = React.useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (data) {
      setValueRows(data.filter((r) => !isNaN(r.value)));
    }
  }, [data]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleExportToExcel = () => {
    exportToExcel(data, status.hardwareData, "nod-export");
    setAnchorEl(null);
  };

  const handleFilterAllRowsChanged = (event) => {
    setFilterShowAllRows(event.target.checked);
  };

  const handleStartDateChange = (newDate) => {
    setStartDate(newDate);
    setEndDateInvalid(newDate.getFullYear() !== endDate.getFullYear());
  };
  const handleEndDateChange = (newDate) => {
    setEndDate(newDate);
    setEndDateInvalid(newDate.getFullYear() !== startDate.getFullYear());
  };

  const handleDataRequested = () => {
    dataRequested(startDate, endDate);
  };
  return (
    <Box>
      {endDateInvalid && (
        <Alert severity="warning" sx={{ marginBottom: 1 }}>
          <AlertTitle>Warning</AlertTitle>
          It's not possible to fetch data passing a new year.{" "}
          <strong>Please make two exports for each year.</strong>
        </Alert>
      )}
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{
          marginBottom: 1,
          padding: 1,
          borderColor: "#eee",
          borderWidth: 1,
        }}
      >
        Start date:{" "}
        <DesktopDatePicker
          label="Date"
          inputFormat="yyyy-MM-dd"
          value={startDate}
          disableFuture={true}
          onChange={handleStartDateChange}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{ marginLeft: 1, marginRight: 1, maxWidth: 160 }}
            />
          )}
        />
        End date:{" "}
        <DesktopDatePicker
          label="Date"
          inputFormat="yyyy-MM-dd"
          value={endDate}
          disableFuture={true}
          minDate={startDate}
          onChange={handleEndDateChange}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{ marginLeft: 1, marginRight: 1, maxWidth: 160 }}
            />
          )}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filterShowAllRows}
              onChange={handleFilterAllRowsChanged}
            />
          }
          label="Show empty rows"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleDataRequested}
          disabled={endDateInvalid}
        >
          Get data
        </Button>
        <div style={{ marginLeft: "auto" }}>
          <Button
            variant="contained"
            color="secondary"
            id="basic-button"
            endIcon={<FileDownloadIcon />}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            Export
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <CSVLink
              data={data ? getCsvData(data, status.hardwareData) : []}
              filename={"nod-export.csv"}
              style={{ color: "#000", textDecoration: "none" }}
            >
              <MenuItem onClick={handleClose}>
                <ViewHeadlineIcon sx={{ marginRight: 1 }} /> CSV
              </MenuItem>
            </CSVLink>
            <MenuItem onClick={handleExportToExcel}>
              <TableChartIcon sx={{ marginRight: 1 }} /> Excel
            </MenuItem>
          </Menu>
        </div>
      </Stack>
      {data &&
        !loading &&
        ((filterShowAllRows && data.length > 250) ||
          (!filterShowAllRows && valueRows > 250)) && (
          <Alert severity="warning" sx={{ marginBottom: 1 }}>
            <AlertTitle>Warning</AlertTitle>
            Data contains more than 250 rows. Only the first 250 rows will be
            displayed in the table below.
            <br />
            <strong>
              Please export the data to get the full dataset, or change your
              dates and/or filters.
            </strong>
          </Alert>
        )}
      {demoMode && (
        <Alert severity="info" sx={{ marginBottom: 1 }}>
          <AlertTitle>Demo mode</AlertTitle>
          Data displayed is fictional and may only be used for demo purposes.
          <br />
          <strong>
            Exit the demo mode and connect to a NOD device to get real data
          </strong>
        </Alert>
      )}
      <Stack direction={"row"}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 550 }} size="small" aria-label="NOD data">
            <TableHead>
              <TableRow>
                <TableCell>Date (Y-M-D)</TableCell>
                <TableCell align="right">Time</TableCell>
                <TableCell align="right">
                  ppm (N<sub>2</sub>O)
                </TableCell>
              </TableRow>
            </TableHead>
            {data && !loading && (
              <TableBody>
                {data
                  .filter(
                    (r) =>
                      filterShowAllRows ||
                      (!filterShowAllRows && !isNaN(r.value))
                  )
                  .slice(0, 250)
                  .map((row) => {
                    return (
                      <TableRow
                        key={row.date + row.time}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {`${startDate.getFullYear()}-${row.date}`}
                        </TableCell>
                        <TableCell align="right">
                          {row.time.slice(0, -3).padStart(5, "0")}
                        </TableCell>
                        <TableCell align="right">
                          {!isNaN(row.value) ? (
                            row.value
                          ) : (
                            <SensorsOffIcon color="warning" />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {!data.some(
                  (r) =>
                    filterShowAllRows || (!filterShowAllRows && !isNaN(r.value))
                ) && (
                  <TableRow key="nodata">
                    <TableCell colSpan={3}>
                      <i>No data registered during the selected days.</i>
                    </TableCell>
                  </TableRow>
                )}
                {((filterShowAllRows && data.length > 250) ||
                  (!filterShowAllRows && valueRows > 250)) && (
                  <TableRow key="nodata">
                    <TableCell colSpan={3}>
                      <Typography display="flex" color="darkorange">
                        <WarningAmberIcon
                          fontSize="small"
                          sx={{ marginRight: 1 }}
                        />{" "}
                        More data is available, see note above the results.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          {loading && (
            <>
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
            </>
          )}
          {!data && !loading && (
            <Typography>No NOD data to display. Fetch data above.</Typography>
          )}
        </TableContainer>
        <Box>
          <NodGraph data={data ? transformNodDataToGraphData(data) : []} />
        </Box>
      </Stack>
    </Box>
  );
};

export default NodDataTable;
