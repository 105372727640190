import React from "react";
import Battery20Icon from "@mui/icons-material/Battery20";
import Battery30Icon from "@mui/icons-material/Battery30";
import Battery50Icon from "@mui/icons-material/Battery50";
import Battery60Icon from "@mui/icons-material/Battery60";
import Battery80Icon from "@mui/icons-material/Battery80";
import Battery90Icon from "@mui/icons-material/Battery90";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import BatteryUnknownIcon from "@mui/icons-material/BatteryUnknown";

const BatteryIcon = (props) => {
  const level = props.level;
  const sx = props.sx;
  if (level && level >= 0 && level <= 1) {
    let levelIcon = <Battery20Icon sx={sx} />;
    if (level > 0.25) levelIcon = <Battery30Icon sx={sx} />;
    if (level > 0.4) levelIcon = <Battery50Icon sx={sx} />;
    if (level > 0.55) levelIcon = <Battery60Icon sx={sx} />;
    if (level > 0.7) levelIcon = <Battery80Icon sx={sx} />;
    if (level > 0.85) levelIcon = <Battery90Icon sx={sx} />;
    if (level > 0.95) levelIcon = <BatteryFullIcon sx={sx} />;
    return levelIcon;
  } else {
    return <BatteryUnknownIcon sx={sx} />;
  }
};

export default BatteryIcon;
